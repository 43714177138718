<template>
  <div class="mt-5">
    <b-container v-if="!loadingStore">
      <div>
        <div>
          <div>
            <h3 class="font-size-58 font-weight-bold mb-4 text-black-store">
              {{ $t("main.stores") }}
            </h3>
          </div>
          <filter-stores @changeLayout="changeLayout">
            <h5 class="heading-filter font-weight-bold">
              {{ $t("main.filter") }}
            </h5>
            <div class="width-filer d-inline-block">
              <main-select
                v-model="selectSort"
                :options="sort"
                class="flex-grow-1 w-50-filter bg-select"
                :placeholder="$t('main.sort')"
                :label="$i18n.locale === 'en' ? 'name_en' : 'name_ar'"
                dir="rtl"
                :reduce="(data) => data.value"
                @selecting="selectSortData"
              />
            </div>
            <template #btn>
              <b-button
                @click="serach"
                class="text-white btn-search font-size-18 d-flex align-items-center justify-content-center gap_1"
              >
                {{ $t("main.search") }}
              </b-button>
            </template>
          </filter-stores>
          <div>
            <b-row
              v-if="loadingStore"
              class="d-flex align-items-center justify-content-center mt-5"
            >
              <b-spinner large type="grow" variant="primary"></b-spinner>
            </b-row>
            <div v-else>
              <b-row v-if="allStores.length > 0">
                <b-col
                  v-for="(store, key) in allStores"
                  :key="key"
                  class="mb-4"
                  cols="12"
                  :class="[
                    {
                      'col-xl-3 col-lg-4 col-md-6 active':
                        selectedView === 'storesGrid',
                      'col-md-12': selectedView !== 'storesGrid',
                    },
                  ]"
                >
                  <transition
                    name="router-anim"
                    :enter-active-class="`animated zoomIn`"
                    mode="out-in"
                    :leave-active-class="`animated zoomOut`"
                  >
                    <component
                      :storeItem="store"
                      :indexOfItem="key + 1"
                      :is="selectedView"
                    ></component>
                  </transition>
                </b-col>
              </b-row>
              <div v-else>
                <not-found-com />
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-pagination :meta="meta" @input="getAllStores" />
    </b-container>

    <b-container
      v-else
      class="d-flex align-items-center justify-content-center"
    >
      <b-spinner large type="grow" variant="primary"></b-spinner>
    </b-container>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import storesGrid from "@/modules/servicePages/marketplace/components/allStores/storesGrid";
import storeList from "@/modules/servicePages/marketplace/components/allStores/storesList";
import filterStores from "../../components/filterMarketPlace";
import marketPlace from "@/modules/servicePages/marketplace/services/marketplace";
import notFoundCom from "@/modules/servicePages/marketplace/components/notFoundCom";
import AppPagination from "../../../../../components/appPagination.vue";
import EventBus from "@/eventBus.js";
export default {
  components: {
    notFoundCom,
    storesGrid,
    filterStores,
    storeList,
    AppPagination,
  },
  data() {
    return {
      defaultImg: require("@/assets/images/ibbil/homePage/various-legumes.jpg"),
      selectedView: "storesGrid",
      allStores: [],
      meta: {},
      loadingStore: true,
      selectSort: this.$route.query.order || "",
      nameOfStore: "any",
      sort: [
        {
          name_en: "All",
          name_ar: "الكل",
          value: "",
        },
        {
          name_en: "Newest to oldest",
          name_ar: "من الأحدث للأقدم",
          value: "DESC",
        },
        {
          name_en: "Oldest to newest",
          name_ar: "من الأقدم للأحدث",
          value: "ASC",
        },
      ],
      city_id:
        JSON.parse(localStorage.getItem("city_id")) == "all"
          ? null
          : JSON.parse(localStorage.getItem("city_id")) || null,
      area_id:
        JSON.parse(localStorage.getItem("area_id")) == "all"
          ? null
          : JSON.parse(localStorage.getItem("area_id")) || null,
    };
  },
  methods: {
    getAllStores(page = 1) {
      this.loadingStore = true;

      marketPlace
        .getAllStores({
          order: this.selectSort,
          page,
          city_id: this.city_id,
          area_id: this.area_id,
        })
        .then((res) => {
          this.allStores = res?.data?.data || [];
          this.nameOfStore = res.data?.category;
          this.meta = res.data?.meta;
        })
        .finally(() => {
          this.loadingStore = false;
        });
    },
    selectSortData(selectSort) {
      this.selectSort = selectSort.value;
    },
    async serach() {
      this.$router.replace({
        query: {
          order: this.selectSort,
        },
      });
      this.getAllStores();
    },
    changeLayout(layout) {
      if (layout === "vertical") {
        this.selectedView = "storesGrid";
      } else {
        this.selectedView = "storeList";
      }
    },
  },
  created() {
    this.getAllStores();
  },
  mounted() {
    core.index();
    EventBus.$on("changeLocation", (data) => {
      this.city_id = data.city_id == "all" ? null : data.city_id;
      this.area_id = data.area_id == "all" ? null : data.area_id;
      this.getAllStores();
    });
  },
  watch: {},
};
</script>
<Style lang="scss" scoped>
.text-black-store {
  color: #000 !important;
}
</Style>
