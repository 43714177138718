<template>
  <div @click="gotToStoreDetails(storeItem)">
    <div
      class="store-card rounded-grid-card cursor-pointer"
      :class="{
        'store-card-ml-0': indexOfItem % 4 === 0,
        'store-card-mr-0': indexOfItem % 4 === 1,
      }"
    >
      <section class="prodCard-image">
        <img
          :src="storeItem.logo ? storeItem.logo : defaultImg"
          :alt="storeItem.name"
          :title="storeItem.name"
        />
      </section>
      <div class="bg-white text-center py-3">
        <h5 class="name-of-store font-weight-bold">{{ storeItem.name }}</h5>
        <!--          <h5 class="name-of-store font-weight-bold">{{storeItem.name.length >= 25 ? storeItem.name.slice(0, 25)+'....' : storeItem.name}}</h5>-->
      </div>
    </div>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";
export default {
  name: "storesGrid",
  props: ["storeItem", "indexOfItem"],
  data() {
    return {
      defaultImg: require("@/assets/images/ibbil/homePage/various-legumes.jpg"),
      defaultComment: 5,
      defaultViews: 12,
      defaultLikes: 8,
      description: "لا يوجد وصف لهاذا المتجر ",
    };
  },
  methods: {
    gotToStoreDetails(payload) {
      this.$router.push({
        name: "storeProfile",
        params: { id: payload.id },
      });
      console.log(payload);
    },
  },
  mounted() {
    core.index();
  },
};
</script>

<style lang="scss" scoped>
.prodCard-image {
  width: 100%;
  height: 315px;
  @media (max-width: 1400px) and (min-width: 1200px) {
    height: 270px;
  }
  @media (max-width: 768px) {
    height: 100%;
  }
}
.prodCard-image img {
  max-height: 100%;
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    object-fit: contain;
  }
}
.store-card {
  overflow: hidden;
  border-radius: 2px;
  width: 315px;
  margin: 0 auto;
  @media (max-width: 1400px) and (min-width: 1200px) {
    width: 270px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
}
.store-card-mr-0 {
  margin-right: 0;
}
.store-card-ml-0 {
  margin-left: 0;
}
</style>
