<template>
  <div class="empty-cart d-flex align-items-center justify-content-center mt-5">
    <img :src="require('@/assets/images/error/image-empty.svg')">
  </div>
</template>

<script>
export default {
  name: 'notFoundCom'
}
</script>

<style>

</style>
