<template>
  <iq-card
    class="d-flex justify-content-between gap_2 p-3 filter flex-lg-row flex-column rounded-4"
  >
    <slot></slot>
    <div class="d-none d-lg-block" v-if="multiLayouts">
      <div class="d-flex justify-end gap_1">
        <span
          @click="
            $emit('changeLayout', 'vertical');
            selectedItem = 1;
          "
        >
          <img
            :src="selectedItem === 1 ? menuActive : menu"
            class="icon-grid"
          />
        </span>
        <span
          @click="
            $emit('changeLayout', 'horizontal');
            selectedItem = 2;
          "
        >
          <img
            :src="selectedItem === 2 ? listActive : list"
            class="icon-grid"
          />
        </span>
      </div>
    </div>
    <slot name="btn"></slot>
  </iq-card>
</template>

<script>
// import { core } from '@/config/pluginInit'

export default {
  props: {
    multiLayouts: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedItem: 1,
      menuActive: require("@/assets/images/icons/menu-active.svg"),
      menu: require("@/assets/images/icons/menu.svg"),
      listActive: require("@/assets/images/icons/table-active.svg"),
      list: require("@/assets/images/icons/table.svg"),
    };
  },
  methods: {
    changeWidth() {
      if (window.innerWidth <= 992) {
        this.$emit("changeLayout", "vertical");
        this.selectedItem = 1;
      }
    },
  },
  mounted() {
    // core.index()
    window.addEventListener("resize", this.changeWidth);
  },
};
</script>

<style lang="scss" scoped>
.filter {
  align-items: center;
  @media (max-width: 992px) {
    gap: 0.5rem !important;
    align-items: flex-start;
  }
  .active path {
    fill: var(--iq-primary) !important;
  }

  svg {
    width: 28px;
    cursor: pointer;

    path {
      fill: #9b9b9b;
    }
  }
}

.iq-card {
  border-radius: 4px !important;
}
.icon-grid {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
</style>
