<template>
  <div>
    <b-card class="">
      <div class="d-flex">
        <div class="store-banner h-100" @click="gotToStoreDetails(storeItem)">
          <img
            :src="storeItem.logo ? storeItem.logo : defaultImg"
            class="store-img"
          />
        </div>
        <div class="px-3" style="flex-grow: 2">
          <div class="flex-column justify-content-between store-detail">
            <div>
              <p
                class="store-detail-heading font-size-22 font-weight-bold"
                @click="gotToStoreDetails(storeItem)"
              >
                {{ storeItem.name }}
              </p>
              <p class="font-size-16 store-detail-paragraph mb-3 w-100">
                {{
                  storeItem?.about?.length >= 50
                    ? storeItem?.about?.slice(0, 50) + "...."
                    : storeItem?.about || description
                }}
              </p>
            </div>
            <div class="d-flex align-items-center">
              <span
                class="m-0 text-white bg-primary font-size-16 p-1 px-3 rounded-50"
                >{{ storeItem.product_count ?? 0 }}</span
              >
              <p class="px-2 m-0 font-size-16 font-weight-bold section">
                {{ $t("main.products") }}
              </p>
            </div>
          </div>
        </div>
        <div class="align-self-end px-3 border-0">
          <b-button
            :to="{
              name: 'storeProfile',
              params: { id: storeItem.id },
            }"
            variant="primary"
            class="d-flex align-items-center justify-content-center store-detail-btn px-4 rounded-4"
          >
            <span class="py-3 px-0 font-size-16">
              {{ $t("main.details") }}
            </span></b-button
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { core } from "@/config/pluginInit";

export default {
  name: "storesList",
  props: ["storeItem"],
  data() {
    return {
      defaultImg: require("@/assets/images/ibbil/homePage/various-legumes.jpg"),
      logoImg: require("@/assets/images/logo.svg"),
      description: "لا يوجد وصف لهاذا المتجر ",
      comment: 5,
      views: 12,
      likes: 8,
    };
  },
  methods: {
    gotToStoreDetails(payload) {
      this.$router.push({
        name: "storeProfile",
        params: { id: payload.id },
      });
    },
  },
  mounted() {
    core.index();
  },
};
</script>

<style lang="scss">
.category-item-list {
  background-color: #e9e9e9;
  border-radius: 20px;
  padding: 10px;
}

.store-banner {
  height: 205px;
  .store-img {
    cursor: pointer;
    width: 205px;
    height: 205px;
    object-fit: cover;
    object-position: center;
  }
}
.store-detail {
  height: 100%;
  display: flex;
  padding: 1.5rem 0;
  @media (max-width: 1400px) and (min-width: 1200px) {
    padding: 1.5rem;
  }
}
.store-detail-heading {
  color: #000;
  margin-bottom: 0.7rem !important;
  cursor: pointer;
}
.store-detail-paragraph {
  color: #3d3d3d;
}
.section {
  color: #262626;
}
.section-name {
  padding: 0.5rem 1rem;
}
.small-image {
  width: 145px;
  height: 145px;
  position: absolute;
  left: -75px;
  top: 20%;
}

.font-size-38 {
  font-size: 38px !important;
}

.font-size-23 {
  font-size: 23px !important;
}
.store-detail-btn {
  height: 40px !important;
  margin-bottom: 1.5rem !important;
}
.rounded-50 {
  border-radius: 50px;
}
.card {
  border-radius: 4px !important;
  overflow: hidden;
}
</style>
